import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
/* import { MdOutlineClose as Cross } from "react-icons/md" */
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
{/*       <StaticImage
        className="perks__image--bg"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      /> */}
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <a href="http://www.miyakyo-u.ac.jp/" >
          <Perk
            //title="Built For Content"
            //content="Contentful helps you edit your application with ease as your business expands"
            content="宮城教育大学"
          >
            <StaticImage
              src="../../../static/logos/miyagi-university-of-education-logo.svg"
              alt="Perk Image"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </Perk>
        </a>
{/*         <span className="perks__divider">
          <Cross />
        </span> */}
        <a href="https://www.city.sendai.jp/" >
          <Perk
            //title="Built For Content"
            //content="Contentful helps you edit your application with ease as your business expands"
            content="仙台市"
          >
            <StaticImage
              src="../../../static/logos/sendai-logo.png"
              alt="Perk Image"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </Perk>
        </a>
        <a href="https://sendai-ijimetaisaku.jp/" >
          <Perk
            //title="Built For Content"
            //content="Contentful helps you edit your application with ease as your business expands"
            content="仙台市いじめ防止等対策ポータルサイト"
          >
            <StaticImage
              src="../../../static/logos/sendai-ijimetaisaku-logo.png"
              alt="Perk Image"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </Perk>
        </a>
      </div>
      <br />
      <br />
      <div className="container container__tight">
        <a href="https://www.iftiny.com/jp/" >
          <Perk
            //title="Built For Content"
            //content="Contentful helps you edit your application with ease as your business expands"
            content="イフティニー株式会社"
          >
            <StaticImage
              src="../../../static/logos/iftiny-logo.svg"
              alt="Perk Image"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </Perk>
        </a>
        <a href="https://www.toinx.co.jp/" >
          <Perk
            //title="Built For Content"
            //content="Contentful helps you edit your application with ease as your business expands"
            content="株式会社トインクス"
          >
            <StaticImage
              src="../../../static/logos/toinx-logo.png"
              alt="Perk Image"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </Perk>
        </a>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
