import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="仙台いじめ防止ネットワーク"
/*           subTitle="いじめから子どもたちを守る" */
          subTitle="子どものいじめ問題の解決や防止に向けて、行政・研究機関・民間企業が連携して取り組みます。"
        />
        <BasicTextModule
/*           title="A super-fast theme that is easy to get started, using the power of
            GatsbyJS" */
          title="私たちについて"
          content="わたしたち「仙台いじめ防止ネットワーク」は，子どものいじめ問題の解決や防止に向けて取り組む民間企業・行政・研究機関が連携して，
          それぞれのリソースを持ち寄り，共同で活動するプラットフォームとなることを目指して結成されました。"
          link="/about"
          linkText="詳細"
        />
        <Features
          title="活動内容"
          introduction="わたしたちは、いじめを防止するために様々な活動を行っています。詳しくは以下にてご覧いただけます。"
        />
        <LatestPosts
          title="お知らせ"
          introduction="イベント開催などのお知らせです。詳しくは以下にてご覧いただけます。"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
      </Layout>
    </>
  )
}

export default Index
